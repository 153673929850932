@import "variables.scss";
@import "default.scss";

body {
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
}

* {
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
}

.score {
    position: fixed;
    top: 0;
    right: 0;
    background: yellow;
    z-index: 1000;
    color: black;
    margin: 16px;
    padding: 0.5em;
    text-align: center;
    width: 5em;
    height: 2.2em;
    border-radius: 0.2em;
    min-width: 5.3em;
}

.full {
    position: fixed;
    top: 2.5em;
    right: 0;
    background: burlywood;
    z-index: 1000;
    color: black;
    margin: 16px;
    padding: 0.5em;
    text-align: center;
    // width: 5em;
    height: 2.2em;
    border-radius: 0.2em;
    opacity: 0.5;
}

canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}

.control {
    position: fixed;
    bottom: 0.5em;
    left: 0,5em;
    z-index: 1000;
}

.up, .left, .right {
    width: 5em;
    height: 5em;
    text-align: center;
    background: yellow;;
    vertical-align: middle;
    line-height: 5em;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.5);
    border-radius: 0.5em;
    color: brown;

}
.up {
    width: 5em;
    height: 5em;
    background: cadetblue;
    text-align: center;
}

.hidden {
    display:none
}

.left {
    position: fixed;
    bottom: 0.5em;
    left: 0.5em;
    z-index: 1000;
}

.right {
    position: fixed;
    bottom: 0.5em;
    left: 5.7em;
    z-index: 1000;
}

.up {
    position: fixed;
    bottom: 0.5em;
    right: 0.5em;
    z-index: 1000;
}
